import React, {useEffect, useRef, useState} from 'react';
import Layout from "../../../components/Layout/Layout";
import {
    DeleteOutlined,
    EditOutlined,
    FormOutlined,
    MoreOutlined,
    PlusOutlined
} from "@ant-design/icons";
import {
    Button,
    Card,
    Col, Dropdown,
    Form,
    Input, InputNumber, MenuProps,
    message,
    Row,
    Select, Table, Tooltip,
    Tour,
    TourProps,
    Typography
} from "antd";
import {Brand} from "../../../models/Brand";
import {RequestActivationType} from "../../../models/RequestActivationType";
import {BrandService} from "../../../services/BrandService";
import {
    CreateRequestActivationRequestDto,
    RequestActivationService
} from "../../../services/RequestActivationService";
import {ApiErrorData} from "../../../models/ApiResponse";
import Title from "antd/es/typography/Title";
import {useThemeLayoutConfig} from "../../../config/ThemeDashboardLayoutConfig";
import {ScheduleService} from "../../../services/ScheduleService";
import {Schedule} from "../../../models/Schedule";
import {Store} from "../../../models/Store";
import {StoreService} from "../../../services/StoreService";
import NewPdvConfigurationModal from "./components/NewPdvConfigurationModal";
import Column from "antd/es/table/Column";
import dayjs, {Dayjs} from "dayjs";
import {User} from "../../../models/User";
import {useSelector} from "react-redux";
import ResumeDrawer from "./components/ResumeDrawer";
import {useNavigate} from "react-router-dom";
import RequestActivationSuccessModal from "./components/RequestActivationSuccessModal";
import EditPdvConfigurationModal from "./components/EditPdvConfigurationModal";
import {ActivationGoalService} from "../../../services/ActivationGoalService";
import {ActivationServiceService} from "../../../services/ActivationServiceService";
import {EquipmentAddonService} from "../../../services/EquipmentAddonService";
import {PromotionalMaterialService} from "../../../services/PromotionalMaterialService";
import {RequestActivationSubTypeService} from "../../../services/RequestActivationSubTypeService";
import {ActivationGoal} from "../../../models/ActivationGoal";
import {ActivationService} from "../../../models/ActivationService";
import {EquipmentAddon} from "../../../models/EquipmentAddon";
import {PromotionalMaterial} from "../../../models/PromotionalMaterial";
import {RequestActivationSubType} from "../../../models/RequestActivationSubType";

import './ActivationRequest.scss';
import {ItemInterface} from "../../../models/ItemInterface";

export interface ActivationSchema {
    storeId: number;
    dates: Dayjs[];
    scheduleId: number;
    numberOfCollaborators: number;
}

export interface ActivationContainerSchema {
    id: number;
    name: string;
    activations: ActivationSchema[];
    subtotal: number;
    taxes: number;
    total: number;
}

const SHOW_TOUR_VARIABLE = 'create_request_activation_show_tour';

function ActivationRequest() {
    const themeConfig = useThemeLayoutConfig();
    const navigate = useNavigate();
    const authUser: User = useSelector((state: any) => state.auth);
    const [messageApi, contextHolder] = message.useMessage();

    const [loading, setLoading] = useState(true);
    const [tableLoading, setTableLoading] = useState(false);
    const [drawerLoading, setDrawerLoading] = useState(false);

    const [brands, setBrands] = useState<Brand[]>([]);
    const [requestActivationTypes, setRequestActivationTypes] = useState<RequestActivationType[]>([]);
    const [schedules, setSchedules] = useState<Schedule[]>([]);
    const [stores, setStores] = useState<Store[]>([]);
    const [activationGoals, setActivationGoals] = useState<ActivationGoal[]>([]);
    const [activationServices, setActivationServices] = useState<ActivationService[]>([]);
    const [equipmentAddons, setEquipmentAddons] = useState<EquipmentAddon[]>([]);
    const [promotionalMaterials, setPromotionalMaterials] = useState<PromotionalMaterial[]>([]);
    const [requestActivationSubTypes, setRequestActivationSubTypes] = useState<RequestActivationSubType[]>([]);

    const [showForm, setShowForm] = useState(true);
    const [showDrawer, setShowDrawer] = useState(false);

    const [form] = Form.useForm();
    const [activations, setActivations] = useState<ActivationSchema[]>([]);

    /* Tour variables */
    const [openTour, setOpenTour] = useState<boolean>(false);
    const addPdvRef = useRef(null);
    const finishRef = useRef(null);
    const previousRef = useRef(null);

    const [isNewPdvConfigurationModalOpen, setIsNewPdvConfigurationModalOpen] = useState(false);
    const [isEditPdvConfigurationModalOpen, setIsEditPdvConfigurationModalOpen] = useState(false);

    const [open, setOpen] = useState(false);
    const [successMessage, setSuccessMessage] = useState<React.ReactNode | string>();

    const [activationSelected, setActivationSelected] = useState<ActivationSchema>();

    const [requestActivationTypeSelected, setRequestActivationTypeSelected] = useState<ItemInterface | null>(null);

    useEffect(() => {
        init();
    }, []);

    const init = async () => {
        const [
            brandResponse,
            requestActivationTypeResponse,
            scheduleResponse,
            storeResponse,
            activationGoalResponse,
            activationServiceResponse,
            equipmentAddonResponse,
            promotionalMaterialResponse,
            requestActivationSubTypeResponse
        ] = await Promise.all([
            BrandService.getAllAvailableByCurrentUserAndCompany(),
            RequestActivationService.getAllRequestActivationTypes(),
            ScheduleService.getAllBySelectedCompany(),
            StoreService.getAvailableBySelectedCompany(),
            ActivationGoalService.getAll(),
            ActivationServiceService.getAll(),
            EquipmentAddonService.getAll(),
            PromotionalMaterialService.getAll(),
            RequestActivationSubTypeService.getAll()

        ]);

        if(brandResponse.success && requestActivationTypeResponse.success
            && scheduleResponse.success && storeResponse.success
            && activationGoalResponse.success && activationServiceResponse.success
            && equipmentAddonResponse.success && promotionalMaterialResponse.success
            && requestActivationSubTypeResponse.success) {
            setBrands(brandResponse.data);
            setRequestActivationTypes(requestActivationTypeResponse.data);
            setSchedules(scheduleResponse.data);
            setStores(storeResponse.data);
            setActivationGoals(activationGoalResponse.data);
            setActivationServices(activationServiceResponse.data);
            setEquipmentAddons(equipmentAddonResponse.data);
            setPromotionalMaterials(promotionalMaterialResponse.data);
            setRequestActivationSubTypes(requestActivationSubTypeResponse.data);
        }else {
            if(!brandResponse.success) {
                const error = brandResponse.data;
                messageApi.error(error.message as string || 'Hubo un error al intentar obtener las marcas, por favor inténtalo nuevamente.', 3.5);
            }

            if(!requestActivationTypeResponse.success) {
                const error = requestActivationTypeResponse.data;
                messageApi.error(error.message as string || 'Hubo un error al intentar obtener los tipos de activación, por favor inténtalo nuevamente.', 3.5);
            }

            if(!scheduleResponse.success) {
                const error = scheduleResponse.data;
                messageApi.error(error.message as string || 'Hubo un error al intentar obtener los horarios disponibles por favor inténtalo nuevamente.', 3.5);
            }

            if(!storeResponse.success) {
                const error = storeResponse.data;
                messageApi.error(error.message as string || 'Hubo un error al intentar obtener los tiendas disponibles por favor inténtalo nuevamente.', 3.5);
            }

            if(!activationGoalResponse.success) {
                const error = activationGoalResponse.data;
                messageApi.error(error.message as string || 'Hubo un error al intentar obtener los objetivos disponibles por favor inténtalo nuevamente.', 3.5);
            }

            if(!activationServiceResponse.success) {
                const error = activationServiceResponse.data;
                messageApi.error(error.message as string || 'Hubo un error al intentar obtener los servicios disponibles por favor inténtalo nuevamente.', 3.5);
            }

            if(!equipmentAddonResponse.success) {
                const error = equipmentAddonResponse.data;
                messageApi.error(error.message as string || 'Hubo un error al intentar obtener los equipamientos extra por favor inténtalo nuevamente.', 3.5);
            }

            if(!promotionalMaterialResponse.success) {
                const error = promotionalMaterialResponse.data;
                messageApi.error(error.message as string || 'Hubo un error al intentar obtener los materiales promocionales por favor inténtalo nuevamente.', 3.5);
            }

            if(!requestActivationSubTypeResponse.success) {
                const error = requestActivationSubTypeResponse.data;
                messageApi.error(error.message as string || 'Hubo un error al intentar obtener los sub-tipos de activaciones por favor inténtalo nuevamente.', 3.5);
            }
        }

        setLoading(false);
    }

    const nextStep = async (values: any) => {
        setShowForm(false);
        const showTour = localStorage.getItem(SHOW_TOUR_VARIABLE);
        if(!showTour) {
            localStorage.setItem(SHOW_TOUR_VARIABLE, 'true');
            setOpenTour(true);
        }
    };

    const backStep = () => {
        //TODO: confirmación¿?
        setActivations([]);
        setShowForm(true);
    }

    const getSelectedBrandName = () => {
        const brandId = form.getFieldValue('brand');
        const selectedBrand = brands.find((record) => {
            return `${record.id}` === brandId;
        });
        return selectedBrand?.name || 'NOMBRE DE LA MARCA';
    }

    const getSelectedRequestActivationTypeName = () => {
        const requestActivationTypeId = form.getFieldValue('requestActivationType');
        const requestActivationType = requestActivationTypes.find((record) => {
            return `${record.id}` === requestActivationTypeId;
        });
        return requestActivationType?.name || '----';
    }

    const tourSteps: TourProps['steps'] = [
        {
            title: 'Programación de la solicitud',
            description: 'Debes de agregar PDV para programar los días y horarios de la activación.',
            target: () => addPdvRef.current,
            nextButtonProps: {
                children: 'Siguiente'
            }
        },
        {
            title: 'Totalizado',
            description: 'Debes de continuar y revisar el monto totalizado de la proforma antes de finalizar.',
            target: () => finishRef.current,
            nextButtonProps: {
                children: 'Siguiente'
            },
            prevButtonProps: {
                children: 'Anterior'
            }
        },
        {
            title: 'Primer paso',
            description: 'Puedes usar esta opción si deseas volver al primer paso, pero recuerda que perderás los cambios realizados en este paso.',
            target: () => previousRef.current,
            nextButtonProps: {
                children: 'Finalizar'
            },
            prevButtonProps: {
                children: 'Anterior'
            }
        }
    ];

    const showNewPdvConfigurationModal = () => {
        setIsNewPdvConfigurationModalOpen(true);
    }

    const closeNewPdvConfigurationModal = () => {
        setIsNewPdvConfigurationModalOpen(false);
    }

    const submitNewPdvConfigurationModal = (storeIds: number[], dates: Dayjs[], scheduleId: number) => {
        const localActivations = [...activations];

        for (const storeId of storeIds) {
            localActivations.push({
                storeId,
                dates,
                scheduleId,
                numberOfCollaborators: 1
            });
        }

        setActivations(localActivations);
        setIsNewPdvConfigurationModalOpen(false);
    }

    const getDropdownMenu = (row: ActivationSchema): MenuProps['items'] => {

        return [
            {
                key: '1',
                label: <span><EditOutlined style={{ marginRight: '8px' }}/> Editar</span>,
                onClick: () => { showEditPdvConfigurationModal(row); }
            },
            {
                key: '2',
                label: <span><DeleteOutlined style={{ marginRight: '8px' }}/> Remover</span>,
                onClick: () => { removeActivation(row); }
            }
        ];
    }

    const removeActivation = (row: ActivationSchema) => {
        const localActivations = activations.filter((record) => {
            return record.storeId !== row.storeId;
        });
        setActivations(localActivations);
    }

    const findStoreById = (storeId: number): Store | undefined => {
        return stores.find((record) => {
            return record.id == storeId
        });
    }

    const getSubtotal = (row: ActivationSchema, fixed = true) => {
        const requestActivationTypeId = form.getFieldValue('requestActivationType');
        const requestActivationType = requestActivationTypes.find((record) => {
            return `${record.id}` === requestActivationTypeId;
        });

        const dynamicCostMultiplier = (requestActivationType?.activationCosts || []).filter((record) => {
            return record.activationCostTypeCode === 'ACTIVATION_UNIT_PRICE_PER_COLLECTOR_PER_DAY'
        }).map((record) => {
            return record.price;
        }).reduce((accumulator, currentPrice) => {
            return accumulator + currentPrice;
        }, 0);

        const staticCost = 0;

        const subtotal = ((row.numberOfCollaborators * row.dates.length * dynamicCostMultiplier) + staticCost);

        const currency = authUser.company.currency.code;

        return fixed ? (subtotal.toFixed(2) + ` ${currency}`) : subtotal;
    }

    const findScheduleById = (scheduleId: number) => {
        return schedules.find((record) => {
            return record.id === scheduleId;
        });
    }

    const showResumeDrawer = () => {
        const error = activations.some((record) => {
            return record.dates.length < 3;
        });

        if(activations.length === 0) {
            messageApi.error('Debes de añadir al menos un PDV a la configuración.', 3.5);
        }else if(error) {
            messageApi.error('Debes de seleccionar al menos 3 fechas en cada configuración marcada en rojo.', 3.5);
        }else {
            setShowDrawer(true);
        }
    }

    const closeResumeDrawer = () => {
        setShowDrawer(false);
    }

    const submitNewActivationRequest = async (currencyCode: string, taxRate: number, groupByBusinessChains: ActivationContainerSchema[]) => {
        const bodyParams: CreateRequestActivationRequestDto[] = groupByBusinessChains.map((record) => {
            return {
                brandId: parseInt(form.getFieldValue('brand')),
                requestActivationTypeId: parseInt(form.getFieldValue('requestActivationType')),
                requiresSpecialDynamic: (form.getFieldValue('requiresSpecialDynamic') === 1),
                additionalInfo1: form.getFieldValue('campaign'),
                currencyCode,
                subTotalAmount: record.subtotal,
                taxAmount: record.taxes,
                taxRate: taxRate,
                totalAmount: record.total,
                activations: record.activations.map((record) => {
                    return {
                        storeId: record.storeId,
                        dates: record.dates.map((date) => {
                            return date.format('YYYY-MM-DD')
                        }),
                        scheduleId: record.scheduleId,
                        numberOfCollaborators: record.numberOfCollaborators,
                        totalAmount: parseFloat(getSubtotal(record) as string),
                    }
                }),
                activationGoalId: parseInt(form.getFieldValue('activationGoal')),
                activationServiceId: parseInt(form.getFieldValue('activationService')),
                requestActivationSubTypeId: parseInt(form.getFieldValue('requestActivationSubType')),
                promotionalMaterialId: parseInt(form.getFieldValue('promotionalMaterial')),
                equipmentAddonId: parseInt(form.getFieldValue('equipmentAddon'))
            }
        });

        setDrawerLoading(true);

        const responses = [];

        for(const body of bodyParams) {
            const response = await RequestActivationService.createRequestActivation(body);
            responses.push(response);
        }

        setDrawerLoading(false);

        const successfulResponses = responses
            .filter(response => response.success);

        if (successfulResponses.length > 0) {
            const errors = [];
            const requests = [];

            let idx = 1;
            for(const response of responses) {
                if(response.success) {
                    requests.push(response.data.createdId);
                }else {
                    errors.push(idx);
                }
                idx++;
            }

            setSuccessMessage((
                <div>
                    <p>
                        !Felicidades! {requests.length === 1 ?
                        <span>se generó la solicitud con N° <b>{requests[0]}</b></span> :
                        <span>se generaron las solicitudes con N°s <b>{requests.join(', ')}</b></span>}
                    </p>
                    {
                        errors.length > 0 && (
                            <p>
                                Sin embargo, {errors.length === 1 ?
                                <span>la solicitud con N° <b>{errors[0]}</b> no pudo generarse.</span> :
                                <span>las solicitudes con N°s <b>{errors.join(', ')}</b> no pudieron generarse.</span>}
                            </p>
                        )
                    }
                </div>
            ));
            setOpen(true);
        } else {
            const firstError = responses.find(response => !response.success)?.data as ApiErrorData;
            messageApi.error(firstError?.message || 'Hubo un error al intentar generar la solicitud de activación, por favor inténtelo más tarde.', 3.5);
        }
    }

    const getFormattedDatesPerActivation = (row: ActivationSchema) => {
        // @ts-ignore
        const dayJsDates = [...new Set(row.dates)].map((date) => {
            return dayjs(date, 'YYYY-MM-DD');
        });

        const minDate = dayJsDates.reduce((a, b) => a.isBefore(b) ? a : b);
        const maxDate = dayJsDates.reduce((a, b) => a.isAfter(b) ? a : b);

        return `${minDate.format('DD/MM/YYYY')} - ${maxDate.format('DD/MM/YYYY')}`
    }

    const getFormattedTitleDatesPerActivation = (row: ActivationSchema) => {
        // @ts-ignore
        const dayJsDates = [...new Set(row.dates)].map((date) => {
            return dayjs(date, 'YYYY-MM-DD');
        });

        return (
            <span>
                {
                    dayJsDates.map((date) => (
                        <p>{date.format('DD/MM/YYYY')}</p>
                    ))
                }
            </span>
        );
    }

    const showEditPdvConfigurationModal = (activation: ActivationSchema) => {
        setActivationSelected(activation);
        setIsEditPdvConfigurationModalOpen(true);
    }

    const closeEditPdvConfigurationModal = () => {
        setIsEditPdvConfigurationModalOpen(false);
        setActivationSelected(undefined);
    }

    const submitEditPdvConfigurationModal = (activation: ActivationSchema) => {
        const localActivations = [...activations];

        const idx = localActivations.findIndex((record) => {
            return record.storeId === activation.storeId;
        });

        if(idx !== -1) {
            localActivations[idx] = activation;
            setActivations(localActivations);
        }

        closeEditPdvConfigurationModal();
    }

    return (
        <>
            { contextHolder }

            <Layout breadcrumb={[
                    { title: <span><FormOutlined/> Activaciones</span> },
                    { title: 'Solicitar activación' }
                ]}
                title="Solicitar activación"
            >
                {
                    !loading && showForm && (
                        <Card>
                            <Form
                                form={form}
                                layout="vertical"
                                scrollToFirstError
                                onFinish={nextStep}
                            >
                                <Row gutter={24}>
                                    <Col xs={24} md={24} lg={8}>
                                        <Form.Item
                                            name="brand"
                                            label="Marca"
                                            rules={[{ required: true, message: 'Debes de seleccionar una opción.' }]}
                                        >
                                            <Select
                                                style={{ width: '100%' }}
                                                options={brands.map((record) => {
                                                    return {
                                                        value: `${record.id}`,
                                                        label: record.name
                                                    };
                                                })}
                                                showSearch
                                                placeholder="Seleccionar una opción"
                                            />
                                        </Form.Item>
                                    </Col>

                                    <Col xs={24} md={24} lg={8}>
                                        <Form.Item
                                            name="activationService"
                                            label="Servicio"
                                            rules={[{ required: true, message: 'Debes de seleccionar una opción.' }]}
                                        >
                                            <Select
                                                style={{ width: '100%' }}
                                                options={activationServices.map((record) => {
                                                    return {
                                                        value: `${record.id}`,
                                                        label: record.name
                                                    };
                                                })}
                                                placeholder="Seleccionar una opción"
                                            />
                                        </Form.Item>
                                    </Col>

                                    <Col xs={24} md={24} lg={8}>
                                        <Form.Item
                                            name="activationGoal"
                                            label="Objetivo de la activación"
                                            rules={[{ required: true, message: 'Debes de seleccionar una opción.' }]}
                                        >
                                            <Select
                                                style={{ width: '100%' }}
                                                options={activationGoals.map((record) => {
                                                    return {
                                                        value: `${record.id}`,
                                                        label: record.name
                                                    };
                                                })}
                                                placeholder="Seleccionar una opción"
                                            />
                                        </Form.Item>
                                    </Col>

                                    <Col xs={24} md={24} lg={8}>
                                        <Form.Item
                                            name="requiresSpecialDynamic"
                                            label="Dinámica especial"
                                            rules={[{ required: true, message: 'Debes de seleccionar una opción.' }]}
                                        >
                                            <Select
                                                style={{ width: '100%' }}
                                                options={[
                                                    {
                                                        value: 1,
                                                        label: 'SI'
                                                    },
                                                    {
                                                        value: 0,
                                                        label: 'NO'
                                                    }
                                                ]}
                                                placeholder="Seleccionar una opción"
                                            />
                                        </Form.Item>
                                    </Col>

                                    <Col xs={24} md={24} lg={8}>
                                        <Form.Item
                                            name="requestActivationType"
                                            label="Tipo de activación"
                                            rules={[{ required: true, message: 'Debes de seleccionar una opción.' }]}
                                        >
                                            <Select
                                                style={{ width: '100%' }}
                                                options={requestActivationTypes.map((record) => {
                                                    return {
                                                        value: `${record.id}`,
                                                        label: record.name
                                                    };
                                                })}
                                                placeholder="Seleccionar una opción"
                                                onChange={(value, option: any) => {
                                                    setRequestActivationTypeSelected(option);
                                                    form.setFieldValue('requestActivationSubType', null);
                                                }}
                                            />
                                        </Form.Item>
                                    </Col>

                                    <Col xs={24} md={24} lg={8}>
                                        <Form.Item
                                            name="requestActivationSubType"
                                            label={requestActivationTypeSelected ? requestActivationTypeSelected.label : 'Subtipo de activación'}
                                            rules={[{ required: true, message: 'Debes de seleccionar una opción.' }]}
                                        >
                                            <Select
                                                style={{ width: '100%' }}
                                                options={requestActivationSubTypes.filter((record) => {
                                                    return record.requestActivationTypeId == requestActivationTypeSelected?.value;
                                                }).map((record) => {
                                                    return {
                                                        value: `${record.id}`,
                                                        label: record.name
                                                    };
                                                })}
                                                placeholder="Seleccionar una opción"
                                                disabled={!requestActivationTypeSelected}
                                            />
                                        </Form.Item>
                                    </Col>

                                    <Col xs={24} md={24} lg={8}>
                                        <Form.Item
                                            name="promotionalMaterial"
                                            label="Materiales promocionales"
                                            rules={[{ required: true, message: 'Debes de seleccionar una opción.' }]}
                                        >
                                            <Select
                                                style={{ width: '100%' }}
                                                options={promotionalMaterials.map((record) => {
                                                    return {
                                                        value: `${record.id}`,
                                                        label: record.name
                                                    };
                                                })}
                                                placeholder="Seleccionar una opción"
                                            />
                                        </Form.Item>
                                    </Col>

                                    <Col xs={24} md={24} lg={8}>
                                        <Form.Item
                                            name="equipmentAddon"
                                            label="Equipamiento extra"
                                            rules={[{ required: true, message: 'Debes de seleccionar una opción.' }]}
                                        >
                                            <Select
                                                style={{ width: '100%' }}
                                                options={equipmentAddons.map((record) => {
                                                    return {
                                                        value: `${record.id}`,
                                                        label: record.name
                                                    };
                                                })}
                                                placeholder="Seleccionar una opción"
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Row gutter={24}>
                                    <Col span={24}>
                                        <Form.Item
                                            name="campaign"
                                            label="Observaciones adicionales"
                                        >
                                            <Input.TextArea rows={8} placeholder="Ingrese alguna observación adicional" />
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Row gutter={24}>
                                    <Col xs={24} sm={{ span: 8, offset: 16 }} md={{ span: 8, offset: 16 }} lg={{ span: 6, offset: 18}} xl={{ span: 6, offset: 18 }} xxl={{ span: 4, offset: 20 }}>
                                        <Button type="primary" htmlType="submit" block>Continuar</Button>
                                    </Col>
                                </Row>
                            </Form>
                        </Card>
                    )
                }
                {
                    !loading && !showForm && (
                        <>
                            <Card>
                                <div className="filter-container">
                                    <Row gutter={[24, 16]}>
                                        <Col xs={24} md={24} lg={12} xl={10}  xxl={8}>
                                            <Title level={5} style={{ textTransform: 'uppercase', marginBottom: '0', color: themeConfig.token?.colorPrimary }}>{getSelectedBrandName()}</Title>
                                            <Typography>Tipo de activación: <b>{getSelectedRequestActivationTypeName()}</b></Typography>
                                        </Col>
                                        <Col xs={24} md={24} lg={12} xl={14}  xxl={16} className="filter-buttons-container">
                                            <Button onClick={() => { showNewPdvConfigurationModal(); }} type="primary" disabled={tableLoading} ref={addPdvRef}><PlusOutlined /> Agregar PDV</Button>
                                        </Col>
                                    </Row>
                                </div>
                            </Card>

                            <Card style={{ marginTop: '18px' }}>
                                <Table
                                    <ActivationSchema>
                                    dataSource={activations}
                                    bordered
                                    loading={tableLoading}
                                    pagination={false}
                                    size="small"
                                    scroll={activations.length > 0 ? { x: '400px', y: 'auto' } : undefined}
                                    rowKey={(record) => { return record.storeId }}
                                >
                                    {
                                        activations.length > 0 && (
                                            <>
                                                <Column width={300} title="Cadena" ellipsis key="chain" render={(row: ActivationSchema) => (
                                                    <>{findStoreById(row.storeId)?.businessChain?.name}</>
                                                )}/>

                                                <Column width={300} title="Tienda" ellipsis key="chain" render={(row: ActivationSchema) => (
                                                    <span title={`Dirección: ${findStoreById(row.storeId)?.address}`}>{findStoreById(row.storeId)?.name}</span>
                                                )}/>

                                                <Column width={180} title="Fechas" key="dates" render={(row: ActivationSchema) => (
                                                    <Tooltip title={getFormattedTitleDatesPerActivation(row)}>
                                                        <span style={{ cursor: 'point' }}>{ getFormattedDatesPerActivation(row) }</span>
                                                    </Tooltip>
                                                )}/>

                                                <Column width={45} title="Días" key="days" render={(row: ActivationSchema) => (
                                                    <>{row.dates.length}</>
                                                )}/>

                                                <Column width={300} title="Horarios" key="schedule" render={(row: ActivationSchema) => (
                                                    <>{findScheduleById(row.scheduleId)?.label}</>
                                                )}/>

                                                <Column width={120} title="Colaboradores" key="numberOfCollaborators" render={(row: ActivationSchema) => (
                                                    <InputNumber min={1} step={1} size="small" value={row.numberOfCollaborators} onChange={(e) => {
                                                        const localActivations = [...activations];
                                                        const idx = localActivations.findIndex((record) => {
                                                            return record.storeId == row.storeId
                                                        });
                                                        localActivations[idx].numberOfCollaborators = e || 1;
                                                        setActivations(localActivations);
                                                    }} onBlur={(event) => {
                                                        let newValue = parseInt(event.target.value) || 1;
                                                        newValue = newValue < 0 ? 1 : newValue;
                                                        const localActivations = [...activations];
                                                        const idx = localActivations.findIndex((record) => {
                                                            return record.storeId == row.storeId
                                                        });
                                                        localActivations[idx].numberOfCollaborators = newValue;
                                                        setActivations(localActivations);
                                                    }}/>
                                                )}/>

                                                <Column align="right" width={120} title="Subtotal" key="subtotal" render={(row: ActivationSchema) => (
                                                    <>{getSubtotal(row)}</>
                                                )}/>

                                                <Column width={50} align="center" title="" key="actions" render={(row) => (
                                                    <Dropdown menu={ { items: getDropdownMenu(row) } } placement="bottomLeft" trigger={['click']}>
                                                        <Button size="small"><MoreOutlined /></Button>
                                                    </Dropdown>
                                                )} />
                                            </>
                                        )
                                    }
                                    {
                                        activations.length === 0 && (
                                            <>
                                                <Column title="Tiendas" key="id" dataIndex="id" align="center"/>
                                            </>
                                        )
                                    }
                                </Table>
                            </Card>

                            <Form
                                layout="vertical"
                            >
                                <Row gutter={24}>
                                    <Col
                                        style={{ marginTop: '24px' }}
                                        xs={24} sm={{ span: 8 }}
                                        md={{ span: 8}}
                                        lg={{ span: 6}}
                                        xl={{ span: 6 }}
                                        xxl={{ span: 4 }}
                                    >
                                        <Button type="default" onClick={() => { backStep(); }} block ref={previousRef}>Anterior</Button>
                                    </Col>

                                    <Col
                                        style={{ marginTop: '24px' }}
                                        xs={24}
                                        sm={{ span: 8, offset: 8 }}
                                        md={{ span: 8, offset: 8 }}
                                        lg={{ span: 6, offset: 12}}
                                        xl={{ span: 6, offset: 12 }}
                                        xxl={{ span: 4, offset: 16 }}
                                    >
                                        <Button type="primary" onClick={() => { showResumeDrawer() }} block ref={finishRef}>Enviar</Button>
                                    </Col>
                                </Row>
                            </Form>

                            <Tour open={openTour} onClose={() => setOpenTour(false)} steps={tourSteps}/>
                        </>
                    )
                }

                {
                    loading && (
                        <Card loading={true} />
                    )
                }

                {/* Modals */}
                {
                    isNewPdvConfigurationModalOpen && (
                        <NewPdvConfigurationModal
                            isOpen={isNewPdvConfigurationModalOpen}
                            handleModalOk={submitNewPdvConfigurationModal}
                            handleModalCancel={closeNewPdvConfigurationModal}
                            stores={stores}
                            requestActivationTypeId={form.getFieldValue('requestActivationType')}
                            schedules={schedules}
                            activations={activations}
                        />
                    )
                }

                {
                    activationSelected && (
                        <EditPdvConfigurationModal
                            isOpen={isEditPdvConfigurationModalOpen}
                            activation={activationSelected}
                            handleModalOk={submitEditPdvConfigurationModal}
                            handleModalCancel={closeEditPdvConfigurationModal}
                            stores={stores}
                            schedules={schedules}
                            requestActivationTypeId={form.getFieldValue('requestActivationType')}
                            getSubtotal={getSubtotal}
                        />
                    )
                }

                <ResumeDrawer
                    isOpen={showDrawer}
                    closeDrawer={closeResumeDrawer}
                    activations={activations}
                    stores={stores}
                    getSubTotalPerRow={getSubtotal}
                    submit={submitNewActivationRequest}
                    loading={drawerLoading}
                    form={form}
                />

                <RequestActivationSuccessModal
                    isOpen={open}
                    handleModalOk={() => {
                        closeResumeDrawer();
                        navigate('/activations/activation-history');
                    }}
                    message={successMessage}/>
            </Layout>
        </>
    );
}

export default ActivationRequest;
