import {ApiResponse} from "../models/ApiResponse";
import MainBackendAxios from "../utils/MainBackendAxios";
import {FunctionsHelper} from "../utils/FunctionsHelper";
import {EquipmentAddon} from "../models/EquipmentAddon";

export const EquipmentAddonService = {
    getAll: async (): Promise<ApiResponse<EquipmentAddon[]>> => {
        try {
            const response = await MainBackendAxios.get(`/equipment-addon/get-all`);

            return {
                success: true,
                status: response.status,
                data: response?.data?.data,
            };
        } catch (err: any) {
            return FunctionsHelper.getErrorFormat(err);
        }
    },
};
