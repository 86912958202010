import {ApiResponse} from "../models/ApiResponse";
import MainBackendAxios from "../utils/MainBackendAxios";
import {FunctionsHelper} from "../utils/FunctionsHelper";
import {ActivationGoal} from "../models/ActivationGoal";

export const ActivationGoalService = {
    getAll: async (): Promise<ApiResponse<ActivationGoal[]>> => {
        try {
            const response = await MainBackendAxios.get(`/activation-goal/get-all`);

            return {
                success: true,
                status: response.status,
                data: response?.data?.data,
            };
        } catch (err: any) {
            return FunctionsHelper.getErrorFormat(err);
        }
    },
};
